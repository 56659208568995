import React, { useState, useEffect, useCallback, CSSProperties } from 'react';
import { useVersion, useDataProvider, useAuthProvider} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import { User } from '../types';
import Welcome from '../dashboard/Welcome';
import { ListItem, Chip, ListItemText, Dialog, DialogContent, Button, DialogTitle, TextField, FormControlLabel, Switch, DialogActions, DialogContentText, InputAdornment } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import firebase from 'firebase';
import { ToastContainer, toast } from 'react-toastify';
import CheckIcon from '@material-ui/icons/Check';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Alert from '@material-ui/lab/Alert';
import 'react-toastify/dist/ReactToastify.css';
require('firebase/auth');

interface State {
    users?:User[];

}
const useStyles = makeStyles(theme => ({
    root: {
        flex: 1,
    },
    cost: {
        marginRight: '1em',
        color: theme.palette.text.primary,
    },
    
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },

    rootForm: {
	    display: 'flex',
	    flexDirection: 'column',
	    padding: theme.spacing(2),

	    '& .MuiTextField-root': {
	      margin: theme.spacing(1),
	      width: '500px',
	    },
	    '& .MuiButtonBase-root': {
	      margin: theme.spacing(2),
	    },
	  },
     
}));

const randomColor = () =>{
  let hex = Math.floor(Math.random() * 0xFFFFFF);
  let color = "#" + hex.toString(16);

  return color;
}


const styles = {
    flex: { display: 'flex', alignItems: 'center' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '0.5em' },
    rightCol: { flex: 1, marginLeft: '0.5em' },
    singleCol: { marginTop: '1em', marginBottom: '1em' },
    width: {width:50},
    avatar : {width : '40px', height:'40px', background: randomColor(), borderRadius:'50%', display: 'flex', alignItems:'center', justifyContent:'center'},
    button : {width : '25%' ,margin:'15px'}
};

const BackOfficeUserList = () => {

	const [state, setState] = useState<State>({});
	const version = useVersion();
	const dataProvider = useDataProvider();
	const authProvider = useAuthProvider();
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [commission, setCommission] = useState('');
    const [formError, setNewError] = useState(true);
    const [percentageError, setPercentageError] = useState(false);
    const [error, setError] = useState<any>({});
    const [openRoleChangeModal, setOpenRoleChangeModal] = useState(false);
    const [userData, setUserData] = useState<any>();
    const [openCommissionEditModal, setOpenCommissionEditModal] = useState(false);
    const [managerCommission, setManagerCommission] = useState('');
    const [selectedManagerCommission, setSelectedManagerCommission] = useState('');
    let passErrorObj : any = {};

	const fetchBackOfficeUsers = useCallback(async () => {
	    const { data : backOfficeUsers } = await dataProvider.getList<User>(
	        'users',{
	            filter: { IsDeleted: false, IsAccountManager: true, IsAdmin : true },
	            sort: { field: 'date', order: 'DESC' },
	            pagination: { page: 1, perPage: 100 },
	        }
	    );
	    setState(state => ({ ...state, backOfficeUsers }));
	},[dataProvider]);

	const handleClickOpen = (e : Event) => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setOpenRoleChangeModal(false);
        setOpenCommissionEditModal(false);
    };

	const handleSubmit = async (e:any) => {
	    e.preventDefault();
	    firebase.auth().createUserWithEmailAndPassword(email,password).then(async(cred:any) => {
		    const { data : response } = await dataProvider.create<User>(
				'users',{
		            data:{
                        UserId:cred.user.uid,
                        UserEmail:email,
                        IsAccountManager:true,
                        IsAdmin:false,
                        Name:firstName,
                        Lname:lastName,
                        ManagerCommission:commission
                    }
		        }
		    )
		    if(response){
	    		toast.success('User created successfully');
	    		window.location.reload()
		    }
	    }).catch((error => {
	    	toast.error(error.message);
	    }))
	    handleClose();
	};

	const getManagerRevenue = useCallback(async () => {
        const token = await authProvider.getJWTToken();
        var url = `${process.env.REACT_APP_API_KEY}getBackOfficeManager`;
        fetch(url, {
            method: 'get',
            headers: new Headers({
                'Authorization': `Bearer ${token}`, 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Origin': '',  
            })
        }).then(async (response) => response.json())
            .then(async function(data) {
                if(data.isSuccessful){
                	let managerRevenue = await data.data; 
                	setState(state => ({ ...state, managerRevenue }));
                	return managerRevenue;
                }else{
                   toast.error(data.message);
                }
            }).catch((error) => console.log(error));
    },[]);

	const validatePasswords = (password:any) => {
        passErrorObj.emptyPassword = password.length === "" ? 'The password must be required' : '';
        passErrorObj.uppercase = password === "" || !(/[A-Z]/.test(password))  ? 'The password must contain at least one upper case.' : '';
        passErrorObj.lowercase = password === "" || !(/[a-z]/.test(password)) ? 'The password must contain at least one lower case.' : '';
        passErrorObj.number = password === "" || !(/[0-9]/.test(password)) ? 'The password must contain at least one number.' : '';
        passErrorObj.specialCharacter = password === "" || !(/[!@#$%^&*`~/(),.?":{}|<>]/.test(password)) ? 'The password must contain at least one special character.' : '';
        passErrorObj.maxLength = password === "" || password.length < 8 ? 'The password must be between 8 to 32 characters long.' : '';
        passErrorObj.validForm = passErrorObj.uppercase || passErrorObj.lowercase || passErrorObj.number || passErrorObj.specialCharacter || passErrorObj.maxLength || passErrorObj.checkDuplicates? setNewError(true) : setNewError(false);
        passErrorObj.minLength = password === "" || password.length > 32;

        setError({
            ...passErrorObj
        })
    }

    const handleClickOpenChangeRole = (e : any, user:any) => {
        setUserData(user);
        setOpenRoleChangeModal(true);
    };

    const updateManagerRole = async () => {
        const token = await authProvider.getJWTToken();
        var url = `${process.env.REACT_APP_API_KEY}updateAccountManagerRole/?userId=${userData.AccountManagerId}`;
        fetch(url, {
            method: 'post',
            headers: new Headers({
                'Authorization': `Bearer ${token}`, 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Origin': '',  
            })
        }).then(async (response) => response.json())
            .then(async function(data) {
                if(data.isSuccessful){
                   toast.success('manager update successfully');
                   setOpenRoleChangeModal(false);
                   getManagerRevenue();
                }else{
                   toast.error('Something want to wrong');
                }
            }).catch((error) => {
                setError(error.message)
                toast.error('Something want to wrong')
            });
    }

    const handleClickOpenPercentageEditModal = async (e : any, managerCommission:any) => {
        setManagerCommission(managerCommission.ManagerCommission)
        setSelectedManagerCommission(managerCommission)
        setOpenCommissionEditModal(true)
    }

    const editManagerCommission = async(selectedManager:any) => {
        const token = await authProvider.getJWTToken();
        var url = `${process.env.REACT_APP_API_KEY}updateAccountManagerCommission?managerId=${selectedManager.AccountManagerId}&newManagerCommission=${commission}`;
        fetch(url, {
            method: 'post',
            headers: new Headers({
                'Authorization': `Bearer ${token}`, 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Origin': '',  
            })
        }).then(async (response) => response.json())
            .then(async function(data) {
                if(data.isSuccessful){
                    getManagerRevenue();
                    setOpenCommissionEditModal(false)
                    toast.success('Manager Commission changed successfully');
                }else{
                   toast.error(data.message);
                }
            }).catch((error) => toast.error('Something want to wrong'));
    }

	useEffect(() => {
	    fetchBackOfficeUsers();
	    getManagerRevenue();
	}, [version,authProvider]); // eslint-disable-line react-hooks/exhaustive-deps
	
	const {managerRevenue} : any = state;

    return (
	    <> 
			{/*	 <div style={styles.flexColumn as CSSProperties}>
		    	<Welcome />
		    	<Button onClick={(e:any) => {handleClickOpen(e)}} style={styles.button} size="large" variant="outlined" color="primary" aria-label="create">
	             	<AddIcon style={{ marginRight: '4px' }} /> Create User 
	            </Button> 
		    	{	
			    	backOfficeUsers && backOfficeUsers.length ? (
			            <Card className={classes.root}>
			                <CardHeader title={'BackOffice Users'} />
			                <List dense={true}>
			                    {backOfficeUsers?.map((user:any) => (
			                        <ListItem key={user.id} >
			                            <div className={classes.root}>
			                                <Grid container spacing={3}>
			                                    <Grid item xs={12} sm={6}>
			                                        <div style={{display:'flex', alignItems:'center'}}>                                            
			                                            <div style={styles.width}>
			                                                <div style={styles.avatar}>
			                                                    <span>{user.UserEmail.charAt(0).toUpperCase()}</span>
			                                                </div>
			                                            </div>
														<div style={{fontWeight:'bold'}}>
		                                                    <ListItemText secondary={user.UserEmail} style={{ paddingRight: 0 }} />
		                                                </div>
			                                        </div>
			                                    </Grid>
			                                </Grid>
			                            </div>
			                        </ListItem>
			                    ))}
			                </List>
			            </Card>
			    	) : ('Back Office Users not found')
		    	}
	    	</div>*/}

	    	<div style={styles.flexColumn as CSSProperties}>
                <Welcome />
                <Button onClick={(e:any) => {handleClickOpen(e)}} style={styles.button} size="large" variant="outlined" color="primary" aria-label="create">
	             	<AddIcon style={{ marginRight: '4px' }} /> Create Manager 
	            </Button> 
                <Card className={classes.root}>
                    <CardHeader title={'BackOffice Users'} />
                    <div className={classes.root} style={{overflowX: 'auto'}}>
                        <table style={{width:'100%', padding:'15px'}}>
                            <thead>
                                <tr>
                                    <th style={{textAlign:'left'}}>Name</th>
                                    <th style={{textAlign:'left'}}>Managers</th>
                                    <th style={{textAlign:'left'}}>Role</th>
                                    <th style={{textAlign:'left'}}>Manager Revenue</th>
                                    <th style={{textAlign:'left'}}>Commission Percentage</th>
                                    <th style={{textAlign:'left'}}>Commission Amount</th>
                                    <th style={{textAlign:'left'}}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {   
                                    managerRevenue && managerRevenue?.length > 0 ? (
                                        managerRevenue?.map((user:any) => (
                                            <tr key={user.ManagerEmail} style={{border:'1px solid gray', padding:'4px', borderRadius:'10px'}}>
                                                <td>
	                                                <div style={{fontWeight:'bold'}}>
	                                                    <ListItemText secondary={user.Name && user.Lname ? user.Name + '' + user.Lname : 'N/A'} style={{ paddingRight: 0 }} /> 
	                                                </div>
                                                </td>
                                                <td>
                                                    <div style={{paddingBottom:'15px'}}>
                                                        <div style={{display:'flex', alignItems:'center'}}>
                                                            <div style={styles.width}>
                                                                <div style={styles.avatar}>
                                                                    <span>{user && user.ManagerEmail?.charAt(0).toUpperCase()}</span>
                                                                </div>
                                                            </div>
                                                            <div style={{fontWeight:'bold'}}>
                                                                <ListItemText secondary={user.ManagerEmail} style={{ paddingRight: 0 }} /> 
                                                            </div> 
                                                        </div>
                                                    </div>   
                                                </td>
                                                <td>
                                                    <Chip style={{fontWeight:'bold', padding:'15px'}} color="primary" label={user.IsAdmin && !user.IsAccountManager ? 'Admin' : 'Manager'} />
                                                </td>
                                                <td>
	                                                <div style={{fontWeight:'bold'}}>
	                                                    <ListItemText secondary={ user.IsAdmin && !user.IsAccountManager ? ' N/A ' : '$ ' + user.ManagerRevenue.toFixed(2)} style={{ paddingRight: 0 }} /> 
	                                                </div>
                                                </td>
                                                <td>
                                                    <div style={{fontWeight:'bold', display:'flex', alignItems:'center'}}>
                                                        { 
                                                            user && !user.ManagerCommission ? (' N/A ') : 
                                                            (   
                                                                <>
                                                                    <div>
                                                                        <span>{user.ManagerCommission + '%'}</span>
                                                                    </div>
                                                                        <Button onClick={(e:any) => {handleClickOpenPercentageEditModal(e,user)}}>
                                                                            <EditIcon style={{ padding: '10px' }} />
                                                                        </Button>
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                </td>
                                                <td>
                                                    <div style={{fontWeight:'bold', display:'flex', alignItems:'center'}}>
                                                        { 
                                                            user && !user.ManagerCommissionAmount || user.ManagerCommissionAmount == 0  ? ('$ ' + 0) : 
                                                            (   
                                                                <>
                                                                    <div>
                                                                        <span>{user.ManagerCommissionAmount.toFixed(2) + '$'}</span>
                                                                    </div>
                                                                </>
                                                            )
                                                        }
                                                    </div>
                                                </td>
                                                <td>
                                                    {
                                                        user && user.IsAccountManager && !user.IsAdmin ? (
                                                            <Button onClick={(e:any) => {handleClickOpenChangeRole(e,user)}} size="small" variant="outlined" color="primary">
                                                                 Change Manager Role 
                                                            </Button> 
                                                        ) : (
                                                            <>
                                                               <Chip style={{fontWeight:'bold', padding:'15px'}} color="primary" label='ADMIN'  />
                                                            </>
                                                        )
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                    ) : (null)
                                }
                            </tbody>
                        </table>
                    </div>
                </Card>
            </div>

            <div>        
                <Dialog open={open} onClose={handleClose} fullWidth aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Create New Back Office User</DialogTitle>
                    <DialogContent>
                      	<form className={classes.rootForm} onSubmit={handleSubmit}>
					      <TextField
					        label="First Name"
					        variant="outlined"
					        required
					        value={firstName}
					        onChange={e => setFirstName(e.target.value)}
					      />
					      <TextField
					        label="Last Name"
					        variant="outlined"
					        required
					        value={lastName}
					        onChange={e => setLastName(e.target.value)}
					      />
					      <TextField
					        label="Email"
					        variant="outlined"
					        type="email"
					        required
					        value={email}
					        onChange={e => setEmail(e.target.value)}
					      />
                          <TextField
                            label="Manager Commission"
                            variant="outlined"
                            type="number"
                            value={commission}
                            InputProps={{
                                startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                inputProps: { min: 0, max: 100 }
                            }}
                            error={percentageError}
                            onInput = {(e:any) =>{
                                e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,3)
                                if(e.target.value > 100){
                                    setPercentageError(true);
                                }else{
                                    setPercentageError(false)
                                }
                            }}
                            helperText={'Commission must be between 0 to 100%'}
                            onChange={e => setCommission(e.target.value)}
                          />
					      <TextField
					        label="Password"
					        variant="outlined"
					        type="password"
					        required
					        value={password}
					        error={formError}
					        onChange={(e:any) => {setPassword(e.target.value); validatePasswords(e.target.value)}}
					        helperText= {
                                error && error.emptyPassword || error.username ||  error.lowercase || error.number ||
                                error.specialCharacter || error.specialCharacter ||
                                error.maxLength || error.validForm || error.minLength
                            }
					      />
					      <div style={{display:'flex', justifyContent:'end'}}>
					        <Button variant="outlined" onClick={handleClose}>
					          Cancel
					        </Button>
					        <Button disabled={formError || percentageError} type="submit" variant="outlined" color="primary">
					          Create
					        </Button>
					      </div>
					    </form>
                    </DialogContent>
                </Dialog>  
	        </div>

            <div>        
                <Dialog open={openRoleChangeModal} onClose={handleClose} fullWidth aria-labelledby="form-dialog-title">
                    {/*<Alert severity="error">This is an error alert — check it out!</Alert>*/}
                    <DialogTitle id="form-dialog-title">Confirmation to assign as Admin</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        Role for <b>{userData ? userData.ManagerEmail : 'manager'}</b> will be changed from Account Manager to Admin. Once Admin role is assigned, You can't revert back to Account Manager.<br/>
                        Please click on <b>CONFIRM</b> button below to change the role.
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose} color="primary">
                        <span>Cancel</span>
                      </Button>
                      <Button color="primary" onClick={updateManagerRole}>
                        <span>Confirm</span>
                      </Button>
                    </DialogActions>
                </Dialog>  
            </div>

            <div>        
                <Dialog open={openCommissionEditModal} onClose={handleClose} fullWidth aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Change Manager Commission</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Manager Commission
                        </DialogContentText>
                        <>
                            <TextField
                                label="Manager Commission"
                                variant="outlined"
                                type="number"
                                fullWidth
                                defaultValue={managerCommission}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                    inputProps: { min: 0, max: 100 },
                                }}
                                error={percentageError}
                                onInput = {(e:any) =>{
                                    e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,3)
                                    if(e.target.value > 100){
                                        setPercentageError(true);
                                    }else{
                                        setPercentageError(false)
                                    }
                                }}
                                helperText={'Commission must be between 0 to 100%'}
                                onChange={e => setCommission(e.target.value)}
                            />
                        </>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose} color="primary">
                        <span>Cancel</span>
                      </Button>
                      <Button color="primary" disabled={percentageError} onClick={(e:any) => {editManagerCommission(selectedManagerCommission)}}>
                        <span>Edit</span>
                      </Button>
                    </DialogActions>
                </Dialog>  
            </div>
			<ToastContainer
				position="bottom-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
	    </>
	) 
}

export default BackOfficeUserList;