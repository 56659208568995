import * as React from 'react';
import { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
// import LabelIcon from '@material-ui/icons/Label';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import {
    useTranslate,
    DashboardMenuItem,
    MenuItemLink,
    MenuProps,
    ReduxState,
} from 'react-admin';
import { AppState } from '../types';
import users from '../users'
import backOffice from '../backOffice'
import billing from '../billingDetails'
import { useVersion, useDataProvider, useAuthProvider } from 'react-admin';

type MenuName = 'menuCatalog' | 'menuSales' | 'menuCustomers';

const Menu = ({ dense = false }: MenuProps) => {
    const [state, setState] = useState({
        menuCatalog: true,
        menuSales: true,
        menuCustomers: true,
    });
    const dataProvider = useDataProvider();
    const authProvider = useAuthProvider();
    const translate = useTranslate();
    const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
    const version = useVersion();

    useSelector((state: AppState) => state.theme); // force rerender on theme change
    const classes = useStyles();

    const handleToggle = (menu: MenuName) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };


    const fetchUserRole = useCallback(async () => {
        const token = await authProvider.getJWTToken();
        if(token){
            const userId = await localStorage.getItem('UserId')
            var url = `${process.env.REACT_APP_API_KEY}checkrole/${userId}`;
            fetch(url, {
                method: 'post',
                headers: new Headers({
                    'Authorization': `Bearer ${token}`, 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Origin': '',  
                })
            }).then(async (response) => response.json())
                .then(async function(data) {
                    if(data){
                        await JSON.stringify(localStorage.setItem("user", data?.data.IsAdmin)); 
                        await JSON.stringify(localStorage.setItem("manager", data?.data.IsAccountManager)); 
                    }
                }).catch((error) => console.log(error));
            }else{
                console.log(token);
            }
    },[]);

    useEffect(() => {
        fetchUserRole();
    }, [version]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div
            className={classnames(classes.root, {
                [classes.open]: open,
                [classes.closed]: !open,
            })}
        >
            {' '}
            <DashboardMenuItem />
           {/* <SubMenu
                handleToggle={() => handleToggle('menuSales')}
                isOpen={state.menuSales}
                name="pos.menu.sales"
                icon={<orders.icon />}
                dense={dense}
            >*/}
               {/* <MenuItemLink
                    to={{
                        pathname: '/commands',
                        state: { _scrollToTop: true },
                    }}
                    primaryText={translate(`resources.commands.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<orders.icon />}
                    dense={dense}
                />
                <MenuItemLink
                    to={{
                        pathname: '/invoices',
                        state: { _scrollToTop: true },
                    }}
                    primaryText={translate(`resources.invoices.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<invoices.icon />}
                    dense={dense}
                />*/}
            {/*</SubMenu>*/}
          {/*  <SubMenu
                handleToggle={() => handleToggle('menuCatalog')}
                isOpen={state.menuCatalog}
                name="pos.menu.catalog"
                icon={<products.icon />}
                dense={dense}
            >
                <MenuItemLink
                    to={{
                        pathname: '/products',
                        state: { _scrollToTop: true },
                    }}
                    primaryText={translate(`resources.products.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<products.icon />}
                    dense={dense}
                />
                <MenuItemLink
                    to={{
                        pathname: '/categories',
                        state: { _scrollToTop: true },
                    }}
                    primaryText={translate(`resources.categories.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<categories.icon />}
                    dense={dense}
                />
            </SubMenu>*/}
         {/*   <SubMenu
                handleToggle={() => handleToggle('menuCustomers')}
                isOpen={state.menuCustomers}
                name="pos.menu.customers"
                icon={<visitors.icon />}
                dense={dense}
            >
                <MenuItemLink
                    to={{
                        pathname: '/customers',
                        state: { _scrollToTop: true },
                    }}
                    primaryText={translate(`resources.customers.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<visitors.icon />}
                    dense={dense}
                />
                <MenuItemLink
                    to={{
                        pathname: '/segments',
                        state: { _scrollToTop: true },
                    }}
                    primaryText={translate(`resources.segments.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<LabelIcon />}
                    dense={dense}
                />
            </SubMenu>*/}

            {
                <MenuItemLink
                    to={{
                        pathname: '/users',
                        state: { _scrollToTop: true },
                    }}
                    primaryText={translate(`resources.users.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<users.icon />}
                    dense={dense}
                />
            }
             
                
            {
                JSON.parse(localStorage.getItem('user') || 'false') === true  ? (
                    <MenuItemLink
                        to={{
                            pathname: '/backOffice',
                            state: { _scrollToTop: true },
                        }}
                        primaryText={translate(`resources.backOffice.name`, {
                            smart_count: 2,
                        })}
                        leftIcon={<backOffice.icon />}
                        dense={dense}
                    />
            
                ) : ('') 
            }

            {
                <MenuItemLink
                    to={{
                        pathname: '/billing',
                        state: { _scrollToTop: true },
                    }}
                    primaryText={translate(`resources.billingDetails.name`, {
                        smart_count: 2,
                    })}
                    leftIcon={<billing.icon />}
                    dense={dense}
                />
            }

            {/*       <MenuItemLink
                to={{
                    pathname: '/reviews',
                    state: { _scrollToTop: true },
                }}
                primaryText={translate(`resources.reviews.name`, {
                    smart_count: 2,
                })}
                leftIcon={<reviews.icon />}
                dense={dense}
            />*/}
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    open: {
        width: 200,
    },
    closed: {
        width: 55,
    },
}));

export default Menu;
