import React, { useState, useEffect, useCallback, CSSProperties } from 'react';
import { useVersion, useDataProvider, useAuthProvider, Loading } from 'react-admin';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
    ListItem,
    ListItemText,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Button,
    Badge,
    MenuItem,
    Menu,
    IconButton,
    FormControl,
    Select,
    Grid,
    Divider
} from '@material-ui/core';
import firebase from 'firebase';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { User } from '../types';
import Welcome from '../dashboard/Welcome';
import { IMeeting } from '../types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ToastContainer, toast } from 'react-toastify';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
require('firebase/auth');
const _ = require('underscore');


const useStyles = makeStyles(theme => ({
    root: {
        flex: 1
    },
    cost: {
        marginRight: '1em',
        color: theme.palette.text.primary,
    },

    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },

}));

const randomColor = () => {
    let hex = Math.floor(Math.random() * 0xFFFFFF);
    let color = "#" + hex.toString(16);

    return color;
}


const styles = {
    flex: { display: 'flex', alignItems: 'center' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '0.5em' },
    rightCol: { flex: 1, marginLeft: '0.5em' },
    singleCol: { marginTop: '1em', marginBottom: '1em' },
    width: { width: 50 },
    avatar: { width: '40px', height: '40px', background: randomColor(), borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' },
    button: { marginBottom: '1em', marginRight: '5px' }
};

const options = [
    'enable',
    'disable',
    'suspend',
];

const ITEM_HEIGHT = 48;

interface State {
    users?: User[];
    meetings?: IMeeting[];
}

const UserList = () => {
    const [state, setState] = useState<State>({});
    const version = useVersion();
    const dataProvider = useDataProvider();
    const authProvider = useAuthProvider();
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const [isDelete, setIsDeleteOpen] = useState(false);
    const [isWarning, setWarningOpen] = useState(false);
    const [openUserRole, setOpenUserRole] = useState(false);
    const [password, setPasswordValue] = useState<any>();
    const [newPassword, setNewPassword] = useState<any>();
    const [error, setError] = useState<any>({});
    const [formError, setNewError] = useState(true);
    const [checkDuplicates, setCheckDuplicates] = useState(false);
    const [userData, setUserData] = useState<any>();
    const [backOfficeUser, setBackOfficeUser] = useState<any>();
    const [loading, setLoading] = useState(true);
    const [selectedBackOfficeUser, setSelectedBackOfficeUser] = useState<any>();
    const [groupByBackOfficeUser, setGroupByBackOfficeUser] = useState<any>({});
    const [managerEmail, setManagerEmail] = useState<any>();
    let passErrorObj: any = {};

    const fetchBackOfficeUsers = useCallback(async () => {
        const { data: backOfficeUsers } = await dataProvider.getList<User>(
            'users', {
            filter: { IsDeleted: false, IsAdmin: false, IsAccountManager: true },
            sort: { field: 'date', order: 'DESC' },
            pagination: { page: 1, perPage: 100 },
        }
        );
        await setGroupByBackOfficeUser(_.groupBy(backOfficeUsers, 'UserId'))
        setState(state => ({ ...state, backOfficeUsers }));
    }, [dataProvider]);

    const fetchUsers = useCallback(async () => {
        let user = localStorage.getItem('UserId');
        let userRole = await JSON.parse(localStorage.getItem('user') || 'false');
        if (userRole) {
            const { data: users } = await dataProvider.getList<User>(
                'users', {
                filter: { IsDeleted: false, IsAdmin: false, IsAccountManager: false },
                sort: { field: 'date', order: 'DESC' },
                pagination: { page: 1, perPage: 100 },
            }
            );
            _.filter(users, async function (_user: any) {
                if (_user.AccountManagerId && _user.AccountManagerId != null) {
                    _user.isManagerAssign = true;
                } else {
                    _user.isManagerAssign = false;
                }
            })
            setState(state => ({ ...state, users }));
        } else {
            const { data: users } = await dataProvider.getList<User>(
                'users', {
                filter: { IsDeleted: false, IsAdmin: false, IsAccountManager: false, AccountManagerId: user },
                sort: { field: 'date', order: 'DESC' },
                pagination: { page: 1, perPage: 100 },
            }
            );
            _.filter(users, async function (_user: any) {
                if (_user.AccountManagerId && _user.AccountManagerId != null) {
                    _user.isManagerAssign = true;
                } else {
                    _user.isManagerAssign = false;
                }
            })
            setState(state => ({ ...state, users }));
        }

    }, [dataProvider]);


    const fetchMeetings = useCallback(async () => {
        let { data: meetings }: any = await dataProvider.getList<IMeeting>(
            'meetings', {
            filter: { IsDeleted: false },
            sort: { field: 'date', order: 'DESC' },
            pagination: { page: 1, perPage: 100 },
        }
        );
        meetings = _.groupBy(meetings, 'UserId');
        setState(state => ({
            ...state,
            meetings
        }));
    }, [dataProvider]);

    const handleListItemClick = (e: Event, user: any) => {
        setUserData(user);
    };

    const handleClickOpen = (e: Event, user: any) => {
        validatePasswords('');
        setUserData(user);
        setOpen(true);
    };


    const handleDeleteUser = (e: Event, user: any) => {
        setUserData(user);
        if (meetings[user.UserId]?.length) {
            setWarningOpen(true)
        } else {
            setIsDeleteOpen(true);
        }
    }

    const handleClose = () => {
        setOpen(false);
        setWarningOpen(false);
        setOpenUserRole(false);
        setIsDeleteOpen(false);
    };

    const passwordValidation = (value: any) => {
        setPasswordValue(value);
    }

    const newPasswordValidation = (value: any) => {
        if (password && password !== value) {
            setCheckDuplicates(true);
        } else {
            setCheckDuplicates(false);
        }
        setNewPassword(value)
    }

    const validatePasswords = (password: any) => {
        passErrorObj.emptyPassword = password.length === "" ? 'The password must be required' : '';
        passErrorObj.uppercase = password === "" || !(/[A-Z]/.test(password)) ? 'The password must contain at least one upper case.' : '';
        passErrorObj.lowercase = password === "" || !(/[a-z]/.test(password)) ? 'The password must contain at least one lower case.' : '';
        passErrorObj.number = password === "" || !(/[0-9]/.test(password)) ? 'The password must contain at least one number.' : '';
        passErrorObj.specialCharacter = password === "" || !(/[!@#$%^&*`~/(),.?":{}|<>]/.test(password)) ? 'The password must contain at least one special character.' : '';
        passErrorObj.maxLength = password === "" || password.length < 8 ? 'The password must be between 8 to 32 characters long.' : '';
        passErrorObj.validForm = passErrorObj.uppercase || passErrorObj.lowercase || passErrorObj.number || passErrorObj.specialCharacter || passErrorObj.maxLength || passErrorObj.checkDuplicates ? setNewError(true) : setNewError(false);
        passErrorObj.minLength = password === "" || password.length > 32;

        setError({
            ...passErrorObj
        })
    }

    const updatePassword = async () => {
        const token = await authProvider.getJWTToken();
        var url = `${process.env.REACT_APP_API_KEY}password/${userData.UserId}?password=${newPassword}`;
        fetch(url, {
            method: 'post',
            headers: new Headers({
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Origin': '',
            })
        }).then((response) => response.json())
            .then(function (data) { setLoading(false); setOpen(false); })
            .catch((error) => setLoading(false));
    }

    const handleClickOpenRoleDialog = (e: Event, user: any) => {
        if (user.AccountManagerId && user.AccountManagerId != null) {
            setManagerEmail(groupByBackOfficeUser[user.AccountManagerId][0].UserEmail)
        } else {
            setManagerEmail(null)
        }
        setUserData(user);
        setOpenUserRole(true);
    }

    const handleActiveUser = async (type: any) => {
        handleCloseMenu();
        const token = await authProvider.getJWTToken();
        var url = `${process.env.REACT_APP_API_KEY}updateUser/${userData.UserId}?status=${type}`;
        fetch(url, {
            method: 'post',
            headers: new Headers({
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Origin': '',
            })
        }).then((response) => response.json())
            .then(function (data) {
                toast.success('User Account is updated Successfully');
                setLoading(false);
                handleCloseMenu();
                fetchUsers();
            })
            .catch((error) => setLoading(false));
    }

    const deleteUser = async () => {
        const token = await authProvider.getJWTToken();
        var url = `${process.env.REACT_APP_API_KEY}delete/${userData.UserId}`;
        fetch(url, {
            method: 'delete',
            headers: new Headers({
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Origin': '',
            })
        }).then((response) => response.json())
            .then(function (data) {
                if (data.isSuccessful) {
                    toast.success('User has been deleted.');
                    setLoading(false);
                    fetchUsers();
                    handleClose();
                } else {
                    toast.error('Something want wrong, plese try again!');
                }
            })
            .catch((error) => setLoading(false));
    }

    const assignManager = (selectedUser: any) => {
        _.filter(backOfficeUsers, function (_backOfficeUser: any) {
            if (_backOfficeUser.UserEmail === selectedUser) {
                updateUserForAssignManager(_backOfficeUser.UserId, userData.UserId)
            }
        });
    }

    const updateUserForAssignManager = useCallback(async (backOfficeUserId, userId) => {
        const token = await authProvider.getJWTToken();
        var url = `${process.env.REACT_APP_API_KEY}updateAccountManagerId/${backOfficeUserId}?userId=${userId}`;
        fetch(url, {
            method: 'post',
            headers: new Headers({
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Origin': '',
            })
        }).then(async (response) => response.json())
            .then(async function (data) {
                if (data.isSuccessful) {
                    toast.success('Manager Assign Successfully');
                    setOpenUserRole(false);
                    fetchUsers();
                } else {
                    toast.error(data.message);
                }
            }).catch((error) => console.log(error));
    }, []);

    const sendEmailToUser = (evane: any, user: any) => {
        console.log('user', user);
    }

    useEffect(() => {
        setLoading(false);
        fetchBackOfficeUsers();
        fetchMeetings();
        fetchUsers();
    }, [version]); // eslint-disable-line react-hooks/exhaustive-deps

    const { users, meetings, backOfficeUsers }: any = state;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const _open = Boolean(anchorEl);
    const filterOptions = ['all', 'enable', 'disable', 'suspend'];
    const [selectedFilter, setSelectedFilter] = useState('all');


    const handleClickMenu = (event: React.MouseEvent<HTMLElement>, user: any) => {
        setAnchorEl(event.currentTarget);
        setUserData(user);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const filteredUsers = users && users?.filter((user: any) => {
        if (selectedFilter === 'all') {
            return true;
        }
        return user.IsAccount === selectedFilter.toLowerCase();
    });

    const handleChange = (event: any) => {
        setSelectedFilter(event.target.value as string);
    };


    if (loading) return <Loading />;

    return (
        <>
            <div>
                <Dialog open={open} onClose={handleClose} fullWidth aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Change User Password.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="password"
                            type="password"
                            id="password"
                            variant="outlined"
                            fullWidth
                            error={formError}
                            helperText=
                            {
                                error && error.emptyPassword || error.username || error.lowercase || error.number ||
                                error.specialCharacter || error.specialCharacter ||
                                error.maxLength || error.validForm || error.minLength
                            }
                            onChange={(event: any) => { const value: any = event.target.value; passwordValidation(value); validatePasswords(value) }}
                        />
                        <TextField
                            margin="dense"
                            id="newPassword"
                            label="confirm password"
                            type="password"
                            variant="outlined"
                            fullWidth
                            error={checkDuplicates}
                            helperText={checkDuplicates ? 'Confirm Passwords must match.' : ''}
                            onChange={(event: any) => { const value: any = event.target.value; newPasswordValidation(value) }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            <span>Cancel</span>
                        </Button>
                        <Button disabled={formError || checkDuplicates} onClick={updatePassword} color="primary">
                            <span>Change</span>
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

            <div>
                <Dialog open={isWarning} onClose={handleClose} fullWidth aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Client can't be deleted.</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Client deletion cannot be completed at this time. This client has upcoming meetings scheduled.
                        </DialogContentText>
                        {userData && meetings[userData?.UserId]?.length && (
                            <div>
                                <ul>
                                    {meetings[userData?.UserId].map((meeting: any) => (
                                        <>
                                            <li key={meeting?.id} style={{padding : '15px'}}>
                                                {meeting.TopicName} - {moment(meeting.StartTime).format('DD-MM-YYYY')}
                                            </li>
                                            <Divider></Divider>
                                        </>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            <span>Cancel</span>
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

            <div>
                <Dialog open={isDelete} onClose={handleClose} fullWidth aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Delete User</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to remove this user?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            <span>Cancel</span>
                        </Button>
                        <Button onClick={deleteUser} color="primary">
                            <span>Delete</span>
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

            <div>
                <Dialog open={openUserRole} onClose={handleClose} fullWidth aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Assign Manager</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Assign Manager
                        </DialogContentText>
                        <>
                            {
                                backOfficeUsers && backOfficeUsers.length > 0 ? (
                                    <Autocomplete
                                        freeSolo
                                        id="free-solo-2-demo"
                                        disableClearable
                                        fullWidth
                                        onChange={(event, value: any) => setSelectedBackOfficeUser(value)}
                                        options={backOfficeUsers ? backOfficeUsers.map((option: any) => option.UserEmail) : 'Mangers not found'}
                                        defaultValue={managerEmail}
                                        renderInput={(params: any) => (
                                            <TextField
                                                {...params}
                                                label="Select BackOffice User"
                                                margin="normal"
                                                variant="outlined"
                                                InputProps={{ ...params.InputProps, type: 'search' }}
                                            />
                                        )}
                                    />
                                ) : (
                                    "No Account Manager user found, Please create an Account Manager user from BackOffice Users page to assign an Account Manager."
                                )

                            }
                        </>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            <span>Cancel</span>
                        </Button>
                        <Button color="primary" disabled={backOfficeUsers && backOfficeUsers.length < 0 ? true : false} onClick={(e: any) => { assignManager(selectedBackOfficeUser) }}>
                            <span>Assign</span>
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

            <div style={styles.flexColumn as CSSProperties}>
                <Welcome />
                <Card className={classes.root}>
                    <div>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <CardHeader title={'Users And Meetings'} />
                            </Grid>
                            <Grid item xs={4}>
                                <div style={{ marginTop: '10px' }}>
                                    <FormControl variant="outlined" fullWidth>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={selectedFilter}
                                            onChange={handleChange}
                                        >
                                            {filterOptions.map((option) => (
                                                <MenuItem key={option} value={option === 'All' ? '' : option.toLowerCase()}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div className={classes.root}>
                        <table style={{ width: '100%', padding: '15px' }}>
                            <thead>
                                <tr>
                                    <th style={{ textAlign: 'left' }}>User And Meetings</th>
                                    <th style={{ textAlign: 'left' }}>Account Managers</th>
                                    <th style={{ textAlign: 'center' }}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    filteredUsers && filteredUsers?.length > 0 ? (
                                        filteredUsers?.map((user: any) => (
                                            <tr key={user.UserId} style={{ border: '1px solid gray', padding: '4px', borderRadius: '10px' }}>
                                                <td>
                                                    <ListItem onClick={(e: any) => handleListItemClick(e, user)} button component={Link} to={{ pathname: '/invoices', search: `?UserId=${user.UserId}` }}>
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <div style={styles.width}>
                                                                <div style={styles.avatar}>
                                                                    <span>{user.UserEmail.charAt(0).toUpperCase()}</span>
                                                                </div>
                                                            </div>
                                                            <div style={{ fontWeight: 'bold' }}>
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <ListItemText secondary={user.UserEmail} style={{ paddingRight: 0 }} />
                                                                    <Badge badgeContent={user?.IsAccount} color={user?.IsAccount === 'enable' ? "primary" : "error"}></Badge>
                                                                </div>
                                                                {
                                                                    meetings ? (
                                                                        <ListItemText
                                                                            secondary={meetings[user.UserId]?.length ? (meetings[user.UserId]?.length) + ' meetings are created by this user' : 0 + ' meetings are created by this user'} style={{ paddingRight: 0 }}
                                                                        />
                                                                    ) : (0)
                                                                }
                                                            </div>
                                                        </div>
                                                    </ListItem>
                                                </td>
                                                <td>
                                                    {
                                                        user && user.isManagerAssign ? (
                                                            <div style={{ alignItems: 'center', display: 'flex' }}>
                                                                {groupByBackOfficeUser[user.AccountManagerId][0].UserEmail}
                                                            </div>
                                                        )
                                                            : (
                                                                <div style={{ alignItems: 'center', display: 'flex' }}>
                                                                    <span>Manager Not Assign</span>
                                                                </div>
                                                            )
                                                    }
                                                </td>
                                                <td>
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        {
                                                            JSON.parse(localStorage.getItem('user') || 'false') === true ? (
                                                                <Button variant="outlined" style={styles.button} onClick={(e: any) => { handleClickOpenRoleDialog(e, user) }}>
                                                                    {user.isManagerAssign ? 'Change Manager' : 'Assign Manager'}
                                                                </Button>
                                                            ) : ('')
                                                        }
                                                        <Button style={styles.button} variant="outlined" onClick={(e: any) => { handleClickOpen(e, user) }}>Change Password</Button>
                                                        <Button style={styles.button} variant="outlined" onClick={(e: any) => { sendEmailToUser(e, user) }}>Send Email</Button>
                                                        <Button style={styles.button} variant="outlined" onClick={(e: any) => { handleDeleteUser(e, user) }}>Delete User</Button>
                                                        <div>
                                                            <IconButton
                                                                aria-label="more"
                                                                id="long-button"
                                                                aria-controls={_open ? 'long-menu' : undefined}
                                                                aria-expanded={_open ? 'true' : undefined}
                                                                aria-haspopup="true"
                                                                onClick={(e: any) => { handleClickMenu(e, user) }}
                                                            >
                                                                <MoreVertIcon />
                                                            </IconButton>
                                                            <Menu
                                                                id="long-menu"
                                                                MenuListProps={{
                                                                    'aria-labelledby': 'long-button',
                                                                }}
                                                                anchorEl={anchorEl}
                                                                open={_open}
                                                                onClose={handleCloseMenu}
                                                                PaperProps={{
                                                                    style: {
                                                                        maxHeight: ITEM_HEIGHT * 4.5,
                                                                        width: '20ch',
                                                                        boxShadow: 'none',
                                                                    },
                                                                }}
                                                            >
                                                                {options.map((option) => (
                                                                    <MenuItem key={option} selected={option === 'Pyxis'} onClick={() => { handleActiveUser(option) }}>
                                                                        {option}
                                                                    </MenuItem>
                                                                ))}
                                                            </Menu>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (null)
                                }
                            </tbody>
                        </table>
                    </div>
                </Card>
            </div>


            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    );
};

export default UserList;