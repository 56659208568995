import React, { useState, useEffect, useCallback, CSSProperties } from 'react';
import { useVersion, useAuthProvider, Loading, useDataProvider} from 'react-admin';
import {TextField,  Grid,} from '@material-ui/core';
import Welcome from '../dashboard/Welcome';
import CardWithIcon from '../dashboard/CardWithIcon';
import ReceiptIcon from '@material-ui/icons/Receipt';
import TimelineIcon from '@material-ui/icons/Timeline';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { makeStyles } from '@material-ui/core/styles';
import GetAppIcon from '@material-ui/icons/GetApp';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { User } from '../types';

interface State {
    users?:User[];
}

const BillingList = () => {
    // const [isSelected, setIsSelected] = useState<Boolean>(false);
    const [state, setState] = useState<State>({});
    const [startDate, setStartDate] = useState<any>(moment().format('DD-MM-YYYY'));
    const [endDate, setEndDate] = useState<any>(moment().format('DD-MM-YYYY'));
    const [invoiceData, setInvoiceData] = useState<any>({});
    const [userID, setUserID] = useState<any>({});
    const version = useVersion();
    const authProvider = useAuthProvider();
    const [loading, setLoading] = useState(true);
    const [selectedManager, setSelectedManager] = useState('');
    const dataProvider = useDataProvider();


    const styles = {
        flex: { display: 'flex', alignItems: 'center' },
        flexColumn: { display: 'flex', flexDirection: 'column' },
        leftCol: { flex: 1, marginRight: '0.5em' },
        rightCol: { flex: 1, marginLeft: '0.5em' },
        singleCol: { marginTop: '1em', marginBottom: '1em' },
        width: {width:50},
        linkbutton: {display: 'flex',justifyContent: 'center',alignItems: 'center'}
    };

    const useStyles = makeStyles((theme) => ({
      container: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 350,
      },
    }));

    const classes = useStyles();

    const fetchInvoiceData = useCallback(async (startDate,endDate,manager) => {
        const token = await authProvider.getJWTToken();
        const userId = await localStorage.getItem('UserId');
        setUserID(userId);
        var url = `${process.env.REACT_APP_API_KEY}getInvoicesByRange/${userId}/${startDate}/${endDate}?manager=${manager}`
        fetch(url, {
            method: 'post',
            headers: new Headers({
                'Authorization': `Bearer ${token}`, 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Origin': '',  
            })
        }).then(async (response) => response.json())
            .then(async function(data) {
                if(!data.isSuccessful){
                    toast.error('no records found with selected dates');
                }
                setInvoiceData(data);
            }).catch((error) => {setLoading(false)});
    },[]);

    const fetchBackOfficeUsers = useCallback(async () => {
        const { data : backOfficeUsers } = await dataProvider.getList<User>(
            'users',{
                filter: { IsDeleted: false, IsAdmin: false, IsAccountManager:true },
                sort: { field: 'date', order: 'DESC' },
                pagination: { page: 1, perPage: 100 },
            }
        );
        setState(state => ({ ...state, backOfficeUsers }));
    },[dataProvider]);

    const selectStartDate = (value:any) => {
        const startDate = moment(value).format('DD-MM-YYYY');
        setStartDate(startDate);
        fetchInvoiceData(startDate,endDate,selectedManager)
    } 

    const selectEndDate = (value:any) => {
        const endDate = moment(value).format('DD-MM-YYYY');
        setEndDate(endDate);
        fetchInvoiceData(startDate,endDate,selectedManager)
    }

    const selectManager = (value:any) => {
        setSelectedManager(value)
        fetchInvoiceData(startDate,endDate,value)
    }


    useEffect(() => {
        setLoading(false);
        fetchBackOfficeUsers();
        fetchInvoiceData(startDate,endDate,selectedManager);
    }, [version]); // eslint-disable-line react-hooks/exhaustive-deps

    if (loading) return <Loading />;
    const { backOfficeUsers } : any = state;

    return (
        <>
            <div style={styles.flexColumn as CSSProperties}>
                <Welcome />
                <div style={{marginRight:'15px', marginBottom: '15px', marginTop:'15px'}}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <form className={classes.container} noValidate>
                              <TextField
                                id="date"
                                label="Start Date"
                                type="date"
                                defaultValue={moment().format('YYYY-MM-DD')}
                                className={classes.textField}
                                InputLabelProps={{shrink: true}}
                                variant="outlined"
                                fullWidth
                                onChange={(event:any) => {const value : any = event.target.value; selectStartDate(value)}}
                              />
                              <div style={{display:'flex', alignItems:'center'}}>
                                  <span style={{padding:'15px'}}> To </span>
                              </div>
                                <TextField
                                    id="date"
                                    label="End Date"
                                    type="date"
                                    defaultValue={moment().format('YYYY-MM-DD')}
                                    className={classes.textField}
                                    InputLabelProps={{shrink: true}}
                                    InputProps={{inputProps: { min: startDate} }}
                                    variant="outlined"
                                    fullWidth
                                    onChange={(event:any) => {const value : any = event.target.value; selectEndDate(value)}}
                                />
                            </form>
                        </Grid>
                        {
                            JSON.parse(localStorage.getItem('user') || 'false') === true  ? (
                                <Grid item xs={12} sm={4}>
                                    {
                                        backOfficeUsers && backOfficeUsers.length > 0 ? (
                                                <Autocomplete
                                                    freeSolo
                                                    id="free-solo-2-demo"
                                                    disableClearable
                                                    fullWidth
                                                    onChange={(event, value:any) => selectManager(value)}
                                                    options={backOfficeUsers ? backOfficeUsers.map((option:any) => option.UserEmail) : 'Mangers not found'}
                                                    defaultValue={'All'}
                                                    renderInput={(params:any) => (
                                                        <TextField
                                                            {...params}
                                                            label="Select Manager"
                                                            margin="normal"
                                                            variant="outlined"
                                                            InputProps={{ ...params.InputProps, type: 'search' }}
                                                            style={{margin:'0px'}}
                                                        />
                                                    )}
                                                />
                                        ) : ("")
                                    }
                                </Grid>
                            ) : ('')
                        }

                        <Grid item xs={12} sm={2}>
                            <div style={styles.linkbutton}>
                                 <GetAppIcon style={{ marginRight: '4px' }} />
                                 <a style={{textDecoration:'none'}} href={`${process.env.REACT_APP_API_KEY}downloadInvoices/${userID}/${startDate}/${endDate}?type=pdf&manager=${selectedManager}`}>Download Invoices</a>  
                            </div>
                        </Grid>
                    </Grid>
                </div>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={3}>
                        <CardWithIcon
                            to=''
                            icon={ReceiptIcon}
                            title={'Total Revenue'}
                            subtitle={invoiceData && !invoiceData.isSuccessful || !invoiceData.InvoiceAmount ? '0' : '$' +  invoiceData?.InvoiceAmount?.toFixed(2) }
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <CardWithIcon
                            to=''
                            icon={TimelineIcon}
                            title={'Total Duration'}
                            subtitle={!invoiceData.isSuccessful ? '0' : invoiceData.Duration + 'hr'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <CardWithIcon
                            to=''
                            icon={InsertDriveFileIcon}
                            title={'Total Invoice'}
                            subtitle={!invoiceData.isSuccessful ? '0' : invoiceData.totalInvoice}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <CardWithIcon
                            to=''
                            icon={InsertDriveFileIcon}
                            title={'Total Used Vouchers'}
                            subtitle={!invoiceData.isSuccessful ? '0 / 0' : invoiceData.usedVouchers + '/' + invoiceData.totalVoucers}
                        />
                    </Grid>
                </Grid>
            </div>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

        </>
    )
}    

export default BillingList;