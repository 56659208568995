import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
    List,
    ListProps,
    Datagrid,
    TextField,
    DateField,
    NumberField,
    DateInput,
    Button,
    Loading
} from 'react-admin';

import InvoiceShow from './InvoiceShow';
import { useLocation } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextFields from '@material-ui/core/TextField';
import { useVersion, useDataProvider, useAuthProvider } from 'react-admin';
import { Invoice, IMeeting } from '../types';
import { DataGrid, GridColDef, GridCellParams } from '@material-ui/data-grid';

const listFilters = [
    <DateInput source="date_gte" alwaysOn />,
    <DateInput source="date_lte" alwaysOn />,
];

interface State {
    invoices?:Invoice;
    meetings?:IMeeting[];
}


const InvoiceList = (props: ListProps) => {
    const [state, setState] = useState<State>({});
    const authProvider = useAuthProvider();
    const dataProvider = useDataProvider();
    const [open, setOpen] = useState(false);
    const [invoiceData, setInvoiceData] =  useState<any>();
    const [invoiceId, setInvoiceId] =  useState<any>();
    const version = useVersion();
    const location = useLocation();
    const [emails, setEmailArray] = useState<any>();
    const [rows, setRows] = useState<any>();
    const [loading, setLoading] = useState(true);
    let userID = location.search.split('?UserId=')[1];

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const sendInvoice = async () => {
        setLoading(true);
        let MeetingInvoiceId;
        let emailArray : any;
        if(invoiceId){
            MeetingInvoiceId = invoiceId;
        }
        if(emails){
          emailArray = emails?.split();
        }         
        const token = await authProvider.getJWTToken();
        var url = `${process.env.REACT_APP_API_URL}invoice/email?id=${invoiceId}&type=pdf`

        fetch(url, {
            method: 'post',
            headers: new Headers({
                'Authorization': `Bearer ${token}`, 
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Origin': '',  
            }),
            body: JSON.stringify({
                'emails': emailArray,
            })
        }).then((response) => response.json())
            .then(function(data) { setLoading(false); setOpen(false);})
              .catch((error) => setLoading(false));
    }

    const fetchInvoice = useCallback(async () => {
        setLoading(true);
        let { data : invoices } : any = await dataProvider.getList<Invoice>(
            'invoices',{
                filter: { IsDeleted: false, UserId: userID, },
                sort: { field: 'date', order: 'DESC' },
                pagination: { page: 1, perPage: 100 },
            }
        );
        setLoading(false);
        setInvoiceData(invoices)
    },[dataProvider])

    const fetchMeetings = useCallback( async () => {
      let { data : meetings } : any = await dataProvider.getList<IMeeting>(
            'meetings',{
                filter: { UserId: userID, },
                sort: { field: 'date', order: 'DESC' },
                pagination: { page: 1, perPage: 100 },
            }
        );
            
            let rows = meetings?.map((x:any,i:any) => (
                { StartTime : (new Date(x.StartTime)).toString(),
                  id: x.Id,
                  TopicName: x.TopicName,
                  Participants: x.Participants.length,
                  Duration: x.Duration ? x.Duration + ' min' : 0,
                  Deleted: x.IsDeleted ? 'YES' : 'NO',
                  Description: x.Description,
                  User: x.UserId,
                })
            ) 
            setRows(rows)
        setState(state => ({
            ...state,
            meetings
        }));
    },[dataProvider])

    const ButtonField = (source:any, record:any) => {
        return(<Button onClick={handleClickOpen} label="Send Invoice" />)
    };

     let unmounted = useRef(false);;
     useEffect(() => {
        setLoading(false);
        fetchInvoice();
        fetchMeetings();
        return () => { unmounted.current = true }
    }, [version,fetchInvoice,fetchMeetings]); // eslint-disable-line react-hooks/exhaustive-deps

    if (loading) return <Loading />;
    
    const { invoices, meetings } = state;

    const columns: GridColDef[] = [
      { field: 'StartTime', headerName: 'StartTime', flex: 1 },
      { field: 'id', headerName: 'Meeting ID', flex: 1 },
      { field: 'TopicName', headerName: 'TopicName', flex: 1 },
      { field: 'Participants', headerName: 'Participants', flex: 1 },
      { field: 'Deleted', headerName: 'Removed', flex: 1 },
      { field: 'Description', headerName: 'Description', flex: 1},
      { field: 'Duration', headerName: 'Duration', flex: 1},
      { field: 'Send Email', headerName: 'Send Email', flex: 1,
        renderCell: (params) => (
            <ButtonField label="Send Email" source="InvoiceId" />
        )
      },
    ];

    const currentlySelected = (params: GridCellParams) => {
        invoiceData.forEach((invoice:any) => {
            if(invoice.MeetingId === params.id){
                setInvoiceId(invoice.Id)
            }
        })
        return(<Button onClick={handleClickOpen} label="Send Invoice" />)
    }

    
    return (
        <>        
            <div>        
                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Invoice</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        To send invoice , please enter email address here.
                      </DialogContentText>
                      <TextFields
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Email Address"
                        type="email"
                        fullWidth
                        onChange={(e) => { const value : any = e.target.value; setEmailArray(value) }}
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose} color="primary">
                        <span>Cancel</span>
                      </Button>
                      <Button onClick={sendInvoice} color="primary">
                        <span>Send</span>
                      </Button>
                    </DialogActions>
                </Dialog>  
            </div>

            {rows?.length ? (
                <div style={{ height: 400, width: '100%' }}>
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={5}
                    checkboxSelection
                    disableSelectionOnClick
                    onCellClick={currentlySelected}
                  />
                </div>
            ): (
                 <span>not found</span>
                )}

                <List {...props} filters={listFilters} perPage={25} sort={{ field: 'date', order: 'desc' }} >
                    <Datagrid rowClick="expand" expand={<InvoiceShow />}>
                        <TextField source="id" />
                        <DateField source="CreatedAt" />
                        <TextField source="InvoiceId" />
                        <NumberField source="Quantity" />
                        <NumberField source="ServiceFee" />
                        <NumberField source="VoucherPrice"/>
                        <ButtonField label="Send Email" source="id" />
                    </Datagrid>
                </List>
        </>
    );
};

export default InvoiceList;
