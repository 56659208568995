export const firebaseConfig = {
  apiKey: "AIzaSyDJK9AvaXicIoKpituByKglaiV-C6NMD7I",
  authDomain: "greeteat-cb454.firebaseapp.com",
  projectId: "greeteat-cb454",
  storageBucket: "greeteat-cb454.appspot.com",
  messagingSenderId: "678380692600",
  appId: "1:678380692600:web:1844698ed2ccb89b96b98d"
};

export const firebaseConfigQA = {
  apiKey: "AIzaSyCDdNb5abdgBNBlJykALUF8YLhxbk6M9cc",
  authDomain: "greeteat-web-qa.web.app",
  projectId: "greeteat-web-qa",
  storageBucket: "greeteat-web-qa.appspot.com",
  messagingSenderId: "552912511045",
  appId: "1:552912511045:web:68cf96c2f888b14463832c"
};
