import * as React from 'react';
import { useTranslate } from 'react-admin';
import { IMeeting } from '../types';
import CardWithIcon from './CardWithIcon';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';

interface Props {
    value?:IMeeting[];
}

const MeetingRoom = (props: Props) => {
    const { value = [] } = props;
    const translate = useTranslate();
    return (
       <CardWithIcon
            to="/users"
            icon={MeetingRoomIcon}
            title={translate('pos.dashboard.meeting_summary')}
            subtitle={value.length.toLocaleString()}
        />
    );
};

export default MeetingRoom;