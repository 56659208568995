import * as React from 'react';
import { Admin, Resource } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';

// import authProvider from './authProvider';
import themeReducer from './themeReducer';
import { Login, Layout } from './layout';
import { Dashboard } from './dashboard';
import customRoutes from './routes';
import englishMessages from './i18n/en';

import visitors from './visitors';
import orders from './orders';
import products from './products';
import invoices from './invoices';
import categories from './categories';
import reviews from './reviews';
import users from './users';
import backOffice from './backOffice';
import billing from './billingDetails';

// import dataProviderFactory from './dataProvider';
import {
  FirebaseDataProvider,
  FirebaseAuthProvider,
  RAFirebaseOptions
} from "react-admin-firebase";
import firebase from 'firebase/app';

const config = require("./FIREBASE_CONFIG.js").firebaseConfig;

const options: RAFirebaseOptions = {
  logging: false,
  watch: ["meetings"]
};
const dataProvider = FirebaseDataProvider(config, options);
const authProvider = FirebaseAuthProvider(config, options);
firebase.initializeApp(config)
// var db = firebase.firestore();
// var auth = firebase.auth(); 

// if (window.location.hostname === process.env.REACT_APP_EMULATOR_URL) {
//   db.useEmulator(process.env.REACT_APP_EMULATOR_URL, Number(process.env.REACT_APP_EMULATOR_PORT));
//   auth.useEmulator(process.env.REACT_APP_API_KEY || '');
// }

const i18nProvider = polyglotI18nProvider(locale => {
    if (locale === 'fr') {
        return import('./i18n/fr').then(messages => messages.default);
    }

    // Always fallback on english
    return englishMessages;
}, 'en');

const App = () => {
    return (
        <Admin
            title=""
            // dataProvider={dataProviderFactory(
            //     process.env.REACT_APP_DATA_PROVIDER || ''
            // )}
            dataProvider={dataProvider} 
            customReducers={{ theme: themeReducer }}
            customRoutes={customRoutes}
            authProvider={authProvider}
            dashboard={Dashboard}
            loginPage={Login}
            layout={Layout}
            i18nProvider={i18nProvider}
            disableTelemetry
        >
            <Resource name="customers" {...visitors} />
            <Resource
                name="commands"
                {...orders}
                options={{ label: 'Orders' }}
            />
            <Resource name="users" {...users} />
            <Resource name="invoices" {...invoices}/>
            <Resource name="products" {...products} />
            <Resource name="categories" {...categories} />
            <Resource name="reviews" {...reviews} />
            <Resource name="backOffice" {...backOffice} />
            <Resource name="billing" {...billing} />
        </Admin>
    );
};

export default App;
